<template>
  <div class="text-left">
    <div class="flex items-center">
      <div class="w-4/5 flex items-center">
        <icon
            :data="icons.agence"
            height="60"
            width="60"
            original
        />
        <div class="ml-2 font-c5 text-c16 w-4/5">{{ donnes.label }}</div>
      </div>

      <div class="w-1/5">
        <inputo @info="retourValue" type="number" class="w-4/5" />
      </div>
    </div>
    {{ sendValue }}
  </div>
</template>

<script>
import inputo from '../../helper/form/input'
import agence from '../../../assets/icons/agenceBlue.svg'

export default {
  name: "Index",

  components: {
    inputo
  },

  props: {
    donnes: {
      type: String,
      default: null
    },
    index: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      response: {},
      icons: {
        agence
      }
    }
  },

  created () {
  },

  methods: {
    retourValue (answer) {
      this.response.quantity = parseInt(answer)
      this.response.index = this.index
      if (this.donnes !== null) {
        this.response.agency = this.donnes.id
      }
      this.$emit('info', this.response)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}
.ensemble{
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 6px;
}
.special{
  border: 0.8px solid #D6DDE2;
  box-sizing: border-box;
  border-radius: 4px;
  margin: -35px 0px 0px 0px;
  background-color: #B0E9FF;
  width: 20%;
}
</style>
