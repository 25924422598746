<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Ajouter une gamme
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">
            <div class="text-left text-c13 mt-8">
              Code
            </div>
            <inputo
                class="mt-3"
                placeholder="DE3823"
                @info="retourCode"
            />

            <div class="text-left text-c13 mt-8">
              Intitulé
            </div>
            <inputo
              class="mt-3"
              placeholder="Luxe"
              @info="retourNom"
            />

            <div class="flex">
              <div class="w-1/2">
                <div class="text-left text-c13 mt-8">
                  Prix compris entre :
                </div>
                <inputo
                    class="mt-3"
                    type="number"
                    placeholder="1000"
                    @info="retourMin"
                />
              </div>

              <div class="w-1/2 ml-6">
                <div class="text-left text-c13 mt-8">
                  Et :
                </div>
                <inputo
                    class="mt-3"
                    type="number"
                    placeholder="4000"
                    @info="retourMax"
                />
              </div>
            </div>

            <div class="text-left text-c13 mt-10">
              Sélectionnez les marques qui fournissent des produits de cette gamme
            </div>
            <multiselect
              :option="optionType"
              :value-t="valueType"
              is-multiple="true"
              height="auto"
              width="36%"
              background="#F4F5F7"
              class="mt-3"
              @info="retourType"
            />

            <div class="mt-8">
              <stock @info="retourStock"/>
            </div>

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c14"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                radius="0px"
                height="55px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import inputo from '../helper/form/input'
import multiselect from '../helper/form/multiselect'
import apiroutes from "../../router/api-routes"
import http from "../../plugins/https"
import stock from '../cdevs/marques/formStock'

export default {
  name: 'Success',
  components: { PopupBase, buton, inputo, multiselect, stock },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      optionType: [],
      valueType: [],
      nom: '',
      error: null,
      charge: false,
      allMarque: [],
      code: '',
      min: null,
      max: null,
      note: '',
      limite: ''
    }
  },

  created() {
    this.allMarques()
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    allMarques () {
      http.get(apiroutes.baseURL + apiroutes.allMarque)
          .then(response => {
            console.log(response)
            this.allMarque = response.reverse()
            for (let item in this.allMarque) {
              this.optionType.push(this.allMarque[item].label)
            }
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    continuer () {
      this.charge = true
      this.error = null
      if (this.nom.length > 0 && this.valueType.length > 0 && this.code.length > 0 && this.min !== null && this.max !== null) {
        let tab = []
        for (let item in this.valueType) {
          for (let i in this.allMarque) {
            if (this.valueType[item] === this.allMarque[i].label){
              tab.push(this.allMarque[i].id)
            }
          }
        }

       const body = {
         label: this.nom,
         brands: tab,
         code: this.code,
         minPrice: this.min,
         maxPrice: this.max,
       }
        this.save(body)
      } else {
        this.charge = false
        this.error = 'Veuillez remplir les champs pour continuer'
      }
    },

    retourLimite(answer) {
      this.limite = answer
    },

    retourStock (answer) {
      this.stockData = answer
    },

    save (body) {
      http.post(apiroutes.baseURL + apiroutes.createGamme, body)
          .then(response => {
            console.log(response)
            this.$emit('oga', true)
            this.saveStock(response.id)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    saveStock (id) {
      const stock = this.stockData

      for (let i in stock) {
        stock[i].brand = id
      }

      http.post(apiroutes.baseURL + apiroutes.limitStock, {limitation: stock})
          .then(response => {
            console.log(response)
            this.charge = false
            this.$emit('oga', false)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    retourNom (answer) {
      this.nom = answer
    },

    retourNote (answer) {
      this.note = answer
    },

    retourMin (answer) {
      this.min = answer
    },

    retourMax (answer) {
      this.max = answer
    },

    retourCode (answer) {
      this.code = answer
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    retourType (answer) {
      this.valueType = answer
    },

    retourAdresse (answer) {
      this.adresse = answer
    },

    retourTelephone (answer) {
      this.telephone = answer
    },

    retourSequestre (answer) {
      this.sequestre = answer
    },

    retourService (answer) {
      this.service = answer
    },

    retourLivraison (answer) {
      this.livraison = answer
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
