<template>
  <div class="ensemble p-6 pb-12">
    <div class="text-43 text-c12 p-1 special">
      Stock de rupture
    </div>

    <div class="flex font-c6 text-c14 mt-10 text-left">
      <div class="w-4/5">
        Stocks
      </div>
      <div class="w-1/5">
        Quantité
      </div>
    </div>

    <div class="flex justify-center">
      <easy-spinner
          v-if="charge"
          type="dots"
          size="40"
      />
    </div>

    <div class="w-full mt-6" v-for="(item, index) in allPoints" :key="index">
      <list :donnes="item" :index="index" @info="retourListe"/>
    </div>
  </div>
</template>

<script>
import list from './stockList'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",

  components: {
    list
  },

  props: {
    type: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      response: {},
      charge: false,
      allPoints: [],
      stock: []
    }
  },

  created () {
    this.allAgence()
  },

  methods: {
    allAgence(){
      this.charge = true
      http.get(apiroutes.baseURL + apiroutes.allAgency)
          .then(response => {
            console.log(response)
            this.allPoints = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourListe (answer) {
      this.stock[answer.index] = answer
      this.$emit('info', this.stock)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}
.ensemble{
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 6px;
}
.special{
  border: 0.8px solid #D6DDE2;
  box-sizing: border-box;
  border-radius: 4px;
  margin: -35px 0px 0px 0px;
  background-color: #B0E9FF;
  width: 30%;
}
</style>
