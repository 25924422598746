<template>
  <div class="w-full">
    <div
      v-if="charge"
      class="flex justify-center mt-16"
    >
      <easy-spinner
        type="dots"
        size="70"
      />
    </div>

    <div class="flex flex-wrap">
      <div
        v-for="(item, index) in allMarque"
        :key="index"
        class="pr-6 w-1/3 mb-6"
      >
        <div class="bg-f5 h-92 rounded-5 w-full flex items-center p-6 item cursor-pointer">
          <icon
            :data="icons.etiquette"
            height="50"
            width="50"
            class="mr-4 cursor-pointer"
            original
          />
          <div class="font-c5 text-c18">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import etiquette from '../../../assets/icons/etiquette.svg'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
  },
  data () {
    return {
      icons: {
        etiquette
      },
      allMarque: [],
      charge: true
    }
  },

  created () {
    this.allMarques()
  },

  methods: {
    allMarques () {
      http.get(apiroutes.baseURL + apiroutes.allMarque)
          .then(response => {
            this.allMarque = response.reverse()
            this.charge = false
            this.$emit('totalVerre', response.length)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  box-shadow: 1px 2px 10px 0px #120c5d38;
}
</style>
