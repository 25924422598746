<template>
  <div class="p-10 pr-16">
    <newMarque
      v-if="activeMarque"
      :activation="activeMarque"
      @oga="retourMarque"
    />
    <new-gamme
      v-if="activeGamme"
      :activation="activeGamme"
      @oga="retourGamme"
    />
    <div
      class="flex items-center"
    >
      <div class="w-2/3 flex text-left text-c48 items-center font-c6">
        <icon
          :data="icons.back"
          height="70"
          width="70"
          class="mr-2 cursor-pointer"
          original
        />
        <div class="ml-4">
          Marques & gammes
        </div>
      </div>

      <div class="w-1/3">
        <div class="flex justify-end">
          <div class="w-4/6">
            <bouton
              :label="buttonLabel"
              radius="5.4px"
              weight="600"
              size="17px"
              @oga="retourClick"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <global-view
        v-if="!reloadSession"
        :etape="etape"
        @label="retourEtape"
      />
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import globalView from '../../component/cdevs/marques/globalData'
import newMarque from '../../component/popup/newMarque'
import newGamme from '../../component/popup/newGamme'

export default {
  name: "Index",

  components: {
    bouton,
    globalView,
    newMarque,
    newGamme
  },

  data () {
    return {
      icons: {
        back
      },
      buttonLabel: 'Ajouter une marque',
      etape: 'Marques',
      activeGamme: false,
      activeMarque: false,
      reloadSession: false,
      allMarque: []
    }
  },

  mounted() {
  },

  methods: {
    retourClick (answer) {
      if (this.etape === 'Marques') {
        this.activeMarque = true
      } else {
        this.activeGamme = true
      }
    },

    retourEtape (answer) {
      this.etape = answer
      if (answer === 'Marques') {
        this.buttonLabel = 'Ajouter une marque'
      } else {
        this.buttonLabel = 'Ajouter une gamme'
      }
    },

    retourMarque (answer) {
      if (answer === true){
        this.reloadSession = answer
        setTimeout(() => {
          this.reloadSession = false
        }, 200)
      }
      this.activeMarque = false
    },

    retourGamme (answer) {
      if (answer === true){
        this.reloadSession = answer
        setTimeout(() => {
          this.reloadSession = false
        }, 200)

      }
      this.activeGamme = false
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
</style>
