<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Modifier une gamme
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">
            <div class="text-left text-c13 mt-8">
              Intitulé
            </div>
            <inputo
              class="mt-3"
              :value-t="nom"
              placeholder="Luxe"
              @info="retourNom"
            />

            <div class="text-left text-c13 mt-10">
              Sélectionnez les marques qui fournissent des produits de cette gamme
            </div>
            <multiselect
              :option="optionType"
              :value-t="valueType"
              is-multiple="true"
              height="auto"
              width="36%"
              background="#F4F5F7"
              class="mt-3"
              @info="retourType"
            />

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c14"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                radius="0px"
                height="55px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import inputo from '../helper/form/input'
import multiselect from '../helper/form/multiselect'
import apiroutes from "../../router/api-routes"
import http from "../../plugins/https"

export default {
  name: 'Success',
  components: { PopupBase, buton, inputo, multiselect },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    },
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      optionType: [],
      valueType: [],
      nom: '',
      error: null,
      charge: false,
      allMarque: []
    }
  },

  created() {
    this.allMarques()
    this.donnes = this.donne
    this.wantToMakeOffer = this.activation
    for (let item in this.donnes.brands) {
      this.valueType.push(this.donnes.brands[item].label)
    }
    this.nom = this.donnes.label
  },

  methods: {
    allMarques () {
      http.get(apiroutes.baseURL + apiroutes.allMarque)
          .then(response => {
            console.log(response)
            this.allMarque = response.reverse()
            for (let item in this.allMarque) {
              this.optionType.push(this.allMarque[item].label)
            }
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    fermer () {
      this.$emit('oga', false)
    },

    continuer (answer) {
      this.charge = true
      this.error = null
      if (this.nom.length > 0 && this.valueType.length > 0) {
        let tab = []
        for (let item in this.valueType) {
          for (let i in this.allMarque) {
            if (this.valueType[item] === this.allMarque[i].label){
              tab.push(this.allMarque[i].id)
              console.log(tab)
            }
          }
        }

        const body = {
          id: this.donnes.id,
          label: this.nom,
          brands: tab
        }
        this.save(body)
      } else {
        this.charge = false
        this.error = 'Veuillez remplir les champs pour continuer'
      }
    },

    save (body) {
      console.log(body)
      http.put(apiroutes.baseURL + apiroutes.updateGamme, body)
          .then(response => {
            console.log(response)
            this.$emit('oga', true)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    retourNom (answer) {
      this.nom = answer
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    retourType (answer) {
      this.valueType = answer
    },

    retourAdresse (answer) {
      this.adresse = answer
    },

    retourTelephone (answer) {
      this.telephone = answer
    },

    retourSequestre (answer) {
      this.sequestre = answer
    },

    retourService (answer) {
      this.service = answer
    },

    retourLivraison (answer) {
      this.livraison = answer
    }
  }
}
</script>

<style lang="scss"  scoped>
@import "../../assets/styles/sass/mixin";
.success {
  z-index: 999;
}
.cardinal {
  width: 42%;
}

.success-container {
  font-family: $font-default;
  width: 100%;
  background-color: #F5F5F5;
  height: auto;
  margin: auto;
  border-radius: 12px;
}
@media screen and (max-width: 700px){
  .success-container {
    width: 100%;
    height: 100vh;
  }
  .titre {
    font-size: 18px;
  }
  .bloc1 {
    width: 100%;
  }
  .bloc2 {
    width: 100%;
    font-size: 18px;
  }
  .button {
    width: 100%;
    font-size: 16px;
  }
  .cardinal {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .cardinal{
    width: 65%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .cardinal{
    width: 90%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}
@media screen and (max-width: 320px){
  .cardinal{
    width: 100%;
  }
  .titre{
    font-size: 14px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 15px;
  }
}
</style>
