<template>
  <div class="rounded-10 h-auto bg-white w-full p-8 all">
    <div class=" w-full">
      <div
        class="w-full"
      >
        <steep-select
          v-if="steep.length > 0"
          :option="steep"
          :value="steepValue"
          @info="retourSteep"
        />
      </div>
    </div>

    <div class="mt-12">
      <div class="text-c20 text-left mt-12">
        <span class="text-c34 font-c7">{{ totalVerre }}</span> {{ steepValue }}
      </div>

      <marque
        v-if="steepValue === 'Marques'"
        @info="retourMarque"
        @totalVerre="retourCount"
      />
      <gamme v-if="steepValue === 'Gammes'" @totalVerre="retourCount" />
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import steepSelect from '../../helper/form/steepSelect'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import marque from './marqueTemplate'
import gamme from './gammeTemplate'

export default {
  name: "Index",
  components: {
    steepSelect,
    marque,
    gamme
  },

  props: {
    etape: {
      type: String,
      default: 'Marques'
    }
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down
      },
      steep: ['Marques', 'Gammes'],
      steepValue: 'Marques',
      allMarque: [],
      totalVerre: 0
    }
  },

  created () {
    this.steepValue = this.etape
  },

  methods: {
    retourSteep(answer) {
      this.steepValue = answer
      this.$emit('label', answer)
    },

    retourMarque(answer) {
      this.$emit('info', answer)
    },

    retourCount (answer) {
      this.totalVerre = answer
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.all{
  min-height: 600px;
}
</style>
