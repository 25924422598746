<template>
  <div class="">
    <update-gamme
      v-if="activeUpdate"
      :activation="activeUpdate"
      :donne="selectedItem"
      @oga="retourUpdate"
    />

    <div
      v-if="charge"
      class="flex justify-center mt-16"
    >
      <easy-spinner
        type="dots"
        size="70"
      />
    </div>

    <div
      v-for="(item, index) in allGamme"
      :key="index"
      class="mt-4"
    >
      <div
        v-if="index > 0"
        class="item mt-4"
      />
      <div class="flex w-full text-left items-center pr-16 mt-4 cursor-pointer yes">
        <div class="w-4/5">
          <div class="text-c16">
            {{ item.label }}
          </div>
          <div class="text-c13 mt-2">
            Marques : <span
              v-for="(i, o) in item.brands"
              :key="o"
              class="text-90"
            > {{ i.label }} <span v-if="o < item.brands.length -1">, </span> </span>
          </div>
        </div>

        <div class="w-1/5 flex justify-end">
          <icon
            :data="icons.update"
            height="20"
            width="20"
            class="cursor-pointer"
            original
            @click="updater(item)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import update from '../../../assets/icons/update.svg'
import updateGamme from '../../popup/updateGamme'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    updateGamme
  },
  data () {
    return {
      icons: {
        update
      },
      allGamme: [],
      activeUpdate: false,
      charge: true,
      selectedItem: null
    }
  },

  created () {
    this.allGammes()
  },

  methods: {
    allGammes () {
      http.get(apiroutes.baseURL + apiroutes.allGamme)
          .then(response => {
            this.allGamme = response.reverse()
            this.charge = false
            this.$emit('totalVerre', response.length)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourUpdate (answer) {
      if (answer) {
        this.allGammes()
      }
      this.activeUpdate = false
    },

    updater (item) {
      this.activeUpdate = true
      this.selectedItem = item
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  border-top: 1px solid #DDDDDD;
}

.yes:hover{
  padding-left: 30px;
  border-left: 3px solid #3521B5;
}
</style>
